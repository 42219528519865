// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
    //console.log('ready'); 
    (function ($) {
      function process_bluegain_sliders(c, e) {
      $('.slider-is-kundenliste').each(function (c, e) {
        let idBlock = $(this).parent().parent().parent().attr('id');
        setTimeout(function(){
          var heights = [];
          $(this).find('.carousel-inner > div').each(function (c, e) {
            height = $(this).outerHeight(true);
            heights.push(height);
          });
          //console.log(heights);
          var newheight = Math.max.apply(Math,heights);
          $(this).find('.carousel-inner > div').css('min-height' , newheight);
        }, 200);
    
    
        
          
    
            // -> kundenliste (Logos & CO)
            // Wrap elms inside wrapper!
    
            if ($(this).hasClass('slider-is-kundenliste')) {
              let items = document.querySelectorAll('#'+idBlock + ' .carousel-item');
              //console.log(idBlock + ' .carousel-item');
              //let items = $(this).find('.carousel-item');
              let  wrapNum = 2;  // default as fallback
    
              if ($(window).width() <= 480) {
                wrapNum = 3;
              }
              if ($(window).width() >= 775) {
                wrapNum = 4;
              }
              if ($(window).width() > 1024) {
                wrapNum = 6;
              }
              if ($(window).width() > 1440) {
                wrapNum = 8;
              }
              //if ($(window).width() > 1680) {
              //  wrapNum = 10;
              //}
              //if ($(window).width() > 1920) {
              //  wrapNum = 12;
              //}
              //if ($(window).width() > 2250) {
              //  wrapNum = 12;
              //}
    
              // OUTDATED:
              // items.forEach((el) => {
              //   const minPerSlide = wrapNum
              //   let next = el.nextElementSibling
              //   for (let i = 1; i < minPerSlide; i++) {
              //     if (!next) {
              //       next = items[0]
              //     }
              //     let cloneChild = next.cloneNode(true)
              //     el.appendChild(cloneChild.children[0])
              //     next = next.nextElementSibling
              //   }
              // });
              //console.log(items);
              var itemcounter = 1;
                items.forEach((el) => {
                  const minPerSlide = wrapNum;
                  let next = items[itemcounter];
                  for (var i = 1; i < minPerSlide; i++) {
                    if(next){
                      let cloneChild = next.cloneNode(true);
                      el.appendChild(cloneChild.children[0]);
                      itemcounter++;
                      next = items[itemcounter];
                    } else {
                      itemcounter++;
                    }
                  }
                  itemcounter++;
                });
    
              $(items).css({ 'transition-duration': (5000 * wrapNum) + 'ms' }); // calculate transition duration
              $(e).find('.inner-item').css({ 'width': (100 / wrapNum) + '%' }); // set width per box inside wrapper row
    
              $(this).find('.carousel-item').each(function(){
                if($(this).children().length <= 1){
                  $(this).css({'border':'1px dotted red'});
                  $(this).remove();
                }
              });
    
              //console.log('bluegain_slider_block \t added counter [' + (c + 1) + '] - kundenliste (patched - with ' + wrapNum + '@' + $(window).width() + ')');
            }
          
        });
      }
        
            //console.log('process_bluegain_sliders \t init');
            process_bluegain_sliders();
            //console.log('process_bluegain_sliders \t done');
        
    
    })(jQuery);
});

$(window).on('resize load', function(){
    if ($(window).width() < 992) {
       $('#bluegain-main-navigation .menu-item .dropdown-menu').removeClass('show');
       //$('#bluegain-main-navigation .current_page_parent > .dropdown-menu').addClass('show');
    }else{
       $('#bluegain-main-navigation .menu-item .dropdown-menu').addClass('show'); 
    }
});
